angular.module("vgresiduos").controller("ClientExternalSystemsPopupCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"clientService",
	"externalSystemService",
	"clientExternalSystem",
	"onCancel",
	"onOk",
	"$stateParams",
	function (
		$scope,
		$rootScope,
		httpService,
		clientService,
		externalSystemService,
		clientExternalSystem,
		onCancel,
		onOk,
		$stateParams
	) {
		"use strict";

		const defaultProvisionalMtrReservedAmount = 20;

		const organizationUnitId = $stateParams.id;
		const systemSources = Vgr.enumerations.destination.systemSource;
		$scope.typesLogin = { cpf: 1, email: 2, other: 3 };

		$scope.generalStatus = Vgr.constants.generalStatus;
		$scope.constants = Vgr.constants;
		$scope.loading = false;
		$scope.isSupplier = clientService.isSupplier();
		$scope.clientExternalSystem = clientExternalSystem;
		$scope.labels = $rootScope.labels;
		$scope.model = {};
		$scope.hasChecked = false;

		$scope.currentTypeLogin = null;
		$scope.skipUnitCode = false;
		$scope.isProvisionalMtrActive = false;
		$scope.isSelectedExternalSystemBeta = false;

		$scope.isPasswordMandatory = function () {
			return $scope.clientExternalSystem ? false : true;
		};

		$scope.cancel = function () {
			onCancel();
		};

		$scope.confirm = function () {
			if (!validate()) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
				return;
			}

			if ($scope.clientExternalSystem) {
				update();
			} else {
				create();
			}
		};

		function update() {
			$scope.loading = true;
			const dto = buildDto();
			httpService.postDTOToServiceV2(externalSystemService.updateClientExternalSystem, dto).then(
				function () {
					$scope.loading = false;
					onOk();
				},
				function () {
					$scope.loading = false;
				}
			);
		}

		function buildDto() {
			const login = $scope.model.login ? $scope.model.login : null;
			return {
				sourceId: $scope.model.externalSystem.Id,
				document: $scope.model.document,
				unitCode: $scope.model.unitCode,
				organizationUnitId: organizationUnitId,
				externalSystemId: $scope.model.externalSystem.Id,
				login: login,
				password: $scope.model.password,
				provisionalMtrReservedAmount: $scope.isProvisionalMtrActive ? $scope.model.provisionalMtrReservedAmount : null,
				userCode: $scope.model.user?.code
			};
		}

		function create() {
			$scope.loading = true;
			const dto = buildDto();
			httpService.postDTOToServiceV2(externalSystemService.createClientExternalSystem, dto).then(
				function (data) {
					$scope.loading = false;
					onOk(data);
				},
				function () {
					$scope.loading = false;
				}
			);
		}

		function validate() {
			$scope.hasChecked = true;

			if (!$scope.model.externalSystem || !$scope.model.externalSystem.Id) {
				return false;
			}

			if ($scope.isSupplier) {
				if (!$scope.model.unitCode) {
					return false;
				}
			} else if (!isValidFields()) {
				return false;
			}

			if ($scope.isProvisionalMtrActive && !isProvisionalMtrAmountValid()) {
				return false;
			}

			return true;
		}

		function isProvisionalMtrAmountValid() {
			return (
				$scope.model.provisionalMtrReservedAmount &&
				$scope.model.provisionalMtrReservedAmount >= $scope.minimumProvisionalMtrAmount &&
				$scope.model.provisionalMtrReservedAmount <= $scope.maximumProvisionalMtrAmount
			);
		}

		$scope.onExternalSystemChange = function (externalSystem) {
			if (externalSystem) {
				$scope.model.externalSystemId = externalSystem.Id;
				$scope.maximumProvisionalMtrAmount = externalSystem.MaximumProvisionalMtrAmount;
				$scope.minimumProvisionalMtrAmount = externalSystem.MinimumProvisionalMtrAmount;
				$scope.isSelectedExternalSystemBeta = externalSystem.IsBeta || false;
				configureFields(externalSystem);
			}
		};

		function configureFields(externalSystem) {
			const externalSystemId = externalSystem ? externalSystem.Id : null;
			$scope.isProvisionalMtrActive = externalSystem ? externalSystem.ProvisionalMTRActive : false;

			$scope.isUnitCodeDisabled = false;

			switch (externalSystemId) {
				case systemSources.SGA_PR:
					$scope.skipUnitCode = true;
					setTypeLogin($scope.typesLogin.other);
					break;
				case systemSources.SGORS_AL:
					if ($scope.model.unitCode) {
						$scope.isUnitCodeDisabled = true;
						$scope.skipUnitCode = false;
					} else {
						$scope.skipUnitCode = true;
						setTypeLogin($scope.typesLogin.other);
					}
					break;
				default:
					$scope.skipUnitCode = false;
					setTypeLogin($scope.typesLogin.cpf);
					break;
			}
		}

		function setTypeLogin(typeLogin) {
			$scope.currentTypeLogin = typeLogin;
		}

		function isValidFields() {
			if (!$scope.model.login) {
				return false;
			}

			if ($scope.requiresUser() && !$scope.model.user) {
				return false;
			}

			if (!$scope.clientExternalSystem && !$scope.model.password) {
				return false;
			}

			if ($scope.currentTypeLogin == $scope.typesLogin.other) {
				return $scope.model.document;
			}
			return $scope.model.document && $scope.model.unitCode;
		}

		$scope.requiresUser = function () {
			return (
				$scope.model.externalSystemId == systemSources.SIGOR_SP &&
				(!$scope.isEdition || $scope.clientExternalSystem.mtrSystemClientUsers?.length == 0)
			);
		};

		function initializePopup() {
			if ($scope.clientExternalSystem) {
				$scope.isEdition = true;
				$scope.model.externalSystemId = $scope.clientExternalSystem.source.id;
				$scope.model.document = $scope.clientExternalSystem.document;
				$scope.model.unitCode = $scope.clientExternalSystem.unitCode;
				$scope.model.login = $scope.clientExternalSystem.login;
				$scope.model.password = $scope.clientExternalSystem.password;
				$scope.model.provisionalMtrReservedAmount = $scope.clientExternalSystem.provisionalMtrReservedAmount;
			} else {
				$scope.isEdition = false;
				$scope.model.provisionalMtrReservedAmount = defaultProvisionalMtrReservedAmount;
			}
		}

		$scope.onUnitCodeChange = function (newUnitCode) {
			if ($scope.clientExternalSystem && newUnitCode != $scope.clientExternalSystem.unitCode) {
				$scope.showUnitCodeAlert = true;
			} else {
				$scope.showUnitCodeAlert = false;
			}
		};

		initializePopup();
	}
]);
