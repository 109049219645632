angular.module("vgresiduos").controller("SupplierSituationFilterCtrl", [
	"$scope",
	"engagementScoreService",
	function ($scope, engagementScoreService) {
		"use strict";

		$scope.situations = Vgr.enumerations.supplier.situation;

		$scope.onRegularSuppliersClick = function () {
			onChangeSituationFilter($scope.situations.Regular);
		};

		$scope.onIrregularSuppliersClick = function () {
			onChangeSituationFilter($scope.situations.Irregular);
		};

		$scope.onInactiveSuppliersClick = function () {
			onChangeSituationFilter($scope.situations.Inactive);
		};

		function onChangeSituationFilter(situation) {
			engagementScoreService.track(Vgr.trackings.supplier.filterByStatus);
			changeSituation(situation);
			$scope.changeGridRows();
		}

		function changeSituation(situation) {
			$scope.situationFilterOptions.situation = situation;
		}
	}
]);
