Vgr.constants = {
	// !############################################
	// !não faça commit de alteração nessas constantes
	portalUrl: "http://localhost:5000",
	s3BaseUrl: "https://vgresiduos-bucket-dev.s3-sa-east-1.amazonaws.com/",
	newHostUrl: "https://apiv2-dev.vgresiduos.com.br/",
	coreHostUrl: "https://apicore-dev.vgresiduos.com.br/",
	biHostUrl: "https://apibi-dev.vgresiduos.com.br/",
	sharedServicesHostUrl: "https://apisharedservices-dev.vgresiduos.com.br/",
	residuesManagementHostUrl: "https://apiresiduesmanagement-dev.vgresiduos.com.br/",
	suppliersHostUrl: "https://apisuppliers-dev.vgresiduos.com.br/",
	residueMarketHostUrl: "https://apiresiduemarket-dev.vgresiduos.com.br/",
	residuesHostUrl: "https://apiresidues-dev.vgresiduos.com.br/",
	integrationsHostUrl: "https://apiintegrations-dev.vgresiduos.com.br/",
	newAngularUrl: "https://dev2.vgresiduos.com.br/",
	// !############################################

	site: "https://vertown.com/",

	featureToggles: {
		permission: {
			ibamaRappIntegration: "3A990770-6BD0-422D-8D88-A3259812F169",
			dnitIntegration: "1DBE85DB-E476-4E84-BABB-4E9255EADBD9",
			goalModule: "E584C6F9-0130-4388-AAF6-D013B4FF0546",
			destinatorModule: "C788F56C-65C5-4E36-8A41-F9B5E9CD11B2",
			esgModule: "86863fec-4765-4768-8c31-ac41418d3094",
			dmrIntegration: "ece40e2f-ee00-4cb0-9d2e-df46885ffcf1",
			enterprisePlan: "2441de7a-ac12-4cb6-86c4-3a13e0d038df",
			spRegulaIntegration: "6160eb07-d88e-4573-8b1c-2c1936ac6f08",
			lowerGenerationPlan: "DFC489C5-D5A0-4945-AC0E-7C4A9E9529C6",
			complianceModule: "a20e09cd-f563-4198-a299-56ca3bfede80",
			maturityModule: "C24BFC94-323C-4F56-B1B3-73E96F351782"
		},
		release: {
			changeLanguage: "155E500B-4963-401C-A9BC-AF7A41A94A4A",
			biModule: "172073e6-4424-4c64-9335-8cd015728fa9",
			newDisposalScreen: "46B29CE9-49C8-4EF0-B1D1-268CC5B31EA1"
		}
	},

	loadingStates: {
		SUCCESS: 2,
		LOADING: 1,
		IDLE: 0,
		ERROR: -1
	},

	generalStatus: {
		success: 1,
		warning: 2,
		error: 3,
		info: 4,
		white: 5,
		black: 6
	},

	decimals: 4,
	moneyDecimals: 2,

	loadingClasses: {
		SUCCESS: "",
		LOADING: "md-accent",
		IDLE: "",
		ERROR: ""
	},

	ANIMATION_DELAY: 1500,
	MAX_FILE_SIZE: 0,
	DEFAULT_ROWS_PER_PAGE: 20,

	applicationInsightsKey: "4ec69e42-4a4a-4eba-8c69-e144d5eb7d6b",

	defaultStorageAlertPercentage: 90,

	socketDefaultTimeout: 360000,

	content: {
		vertownBlueLogoLink: "public/logo_vertown_azul.svg",
		vertownBlackberryLogoLink: "public/logo_vertown_amora.png",
		vertownWhiteLogoLink: "public/logo_vertown_branca.svg",

		termsAndConditionsLink: "public/TermsAndConditions.pdf",
		privacyPolicyLink: "aviso-de-privacidade/",
		cookiePolicyLink: "public/cookiePolicy.pdf"
	},

	zendesk: {
		baseUrl: "https://vertown.zendesk.com/hc/pt-br/articles/",

		guides: {
			enhancedMtrMonitoringScreen: "27972585874452-Como-acompanhar-o-MTR-e-seus-status",
			dashboardBiSuppliers: "28834092206100-Dashboard-de-indicadores",
			customReportCarouselScreen: "27970947618068-Como-criar-um-relat%C3%B3rio-personalizado",
			traceabilityReportPdfScreen: "28102937332372-Relat%C3%B3rio-de-Rastreabilidade",
			supplierFilter: "27972050425108-Gest%C3%A3o-corporativa-de-fornecedores",
			sinirOrientation:
				"33385585124116-SINIR-Orientações-para-recuperação-e-regularização-de-dados-perdidos-06-a-10-12-2024",
			enhancedDisposalModelListScreen: "28003483670164-Como-criar-e-editar-os-modelos-de-MTR",
			emissionDashboardBI: "27793000587156-Painel-de-Emissões"
		}
	},
	feamOrientationUrl: {
		baseUrl:
			"https://meioambiente.mg.gov.br/documents/d/semad/comunicado-53-mtr_atualizacoes-no-sistema_trava-tecnologia-e-recusa-pdf"
	},
	marketingSurvey: {
		baseUrl: "https://pt.surveymonkey.com/r/P52HVXX"
	},

	//translation
	UPDATE_LANGUAGE: "update_language",
	SUPPORTED_LANGUAGES: {
		portuguese: { code: "pt-br", id: "1", name: "Português", enabled: true },
		spanish: { code: "es", id: "2", name: "Español", enabled: true },
		english: { code: "en-us", id: "3", name: "English", enabled: false }
	},

	authentication: {
		client: "session.client",
		account: "session.user",
		previousLoggedSsoIdp: "session.idp",
		permissions: "currentUser.permissions",
		featureToggle: '"currentUser.featureToggle',
		appLogin: "appLogin",
		anonymousEndpoints: [
			".json",
			".js",
			".css",
			".svg",
			".html",
			"/identity-provider",
			"/v1/cities",
			"/v1/accounts",
			"/api/v1/site"
		],
		errors: {
			DefaultError: "DefaultError",
			PasswordResetRequiredException: "PasswordResetRequiredException",
			NotAuthorizedException: "NotAuthorizedException",
			UserNotConfirmedException: "UserNotConfirmedException",
			InvalidPasswordException: "InvalidPasswordException",
			InvalidParameterException: "InvalidParameterException"
		},
		forgotPasswordReturnTypes: {
			newPassword: "new_password",
			code: "code"
		},
		errorMessages: {
			UserDisabled: "User is disabled.",
			SessionExpired: "session is expired"
		},
		challenge: {
			newPasswordRequired: "NEW_PASSWORD_REQUIRED"
		}
	},

	registrationSteps: {
		REGISTER: 1,
		ANALYSIS: 2
	},

	featureToggleAccess: {
		Yes: "Yes",
		No: "No"
	},

	measureUnit: {
		Unit: {
			Id: 110,
			Group: 3
		},
		Ton: {
			Id: 109,
			Group: 2
		},
		Kilo: {
			Id: 108,
			Group: 2
		},
		LITER: {
			Id: 107,
			ConversionFactor: 0.001,
			Group: 1
		},
		M3: {
			Id: 106,
			ConversionFactor: 1,
			Group: 1
		}
	},

	country: {
		Brazil: 1,
		Peru: 169,
		Argentina: 13
	},

	httpStatus: {
		BadRequest: 400,
		Unauthorized: 401,
		Forbidden: 403,
		Notfound: 404
	},

	// events
	evtOpenMenu: "EVENT_OPEN_MENU",
	evtShowMenu: "EVENT_SHOW_MENU",
	evtShowErrorMessage: "EVENT_SHOW_ERROR_MESSAGE",
	evtChangeGridFilters: "EVENT_CHANGE_GRID_FILTERS",
	evtShowWarningMessage: "EVENT_SHOW_WARNING_MESSAGE",
	evtShowInfoMessage: "EVENT_SHOW_INFO_MESSAGE",
	evtExecuteOperation: "EVENT_EXECUTE_OPERATION",
	evtShowSuccessMessage: "EVENT_SHOW_SUCCESS_MESSAGE",
	evtMenuLinkClick: "EVENT_MENU_LINK_CLICK",
	evtConfirmNo: "EVENT_OPERATION_DENIED",
	evtConfirmYes: "EVENT_OPERATION_ACCEPTED",
	evtExecuteAction: "EVENT_OPERATION_EXECUTE",
	evtConfirmOperation: "EVENT_SHOW_CONFIRM_OPERATION_TOAST",
	evtDestroyGrid: "EVENT_DESTROY_GRID",
	evtCloseToast: "CLOSE_TOAST",
	evtCloseUserNavBar: "CLOSE_USER_NAV_BAR",
	evtChangeLoggedClient: "EVENT_CHANGE_CLIENT",
	evtConfimReplicateForClients: "EVENT_CONFIRM_REPLICATE_FOR_CLIENTS",
	evtGetSelectedCount: "EVENT_GET_SELECTED_COUNT",
	evtChangeLoggedUserInfo: "EVENT_CHANGE_LOGGED_USER_INFO",
	evtChangeLoggedClientInfo: "EVENT_CHANGE_LOGGED_CLIENT_INFO",
	evtChangeLoginStatus: "EVENT_CHANGE_LOGIN_STATUS",
	evtAuditsChanged: "EVENT_AUDITS_CHANGED",
	evtActionPlansChanged: "EVENT_ACTION_PLANS_CHANGED",
	evtUpdateFollowUpList: "EVENT_UPDATE_FOLLOWUP",
	evtHasIntegrationClientExternalSystemSinir: "EVENT_HAS_INTEGRATION_CLIENT_EXTERNAL_SYSTEM_SINIR",
	evtHasIntegrationClientExternalSystemSigorSP: "EVENT_HAS_INTEGRATION_CLIENT_EXTERNAL_SYSTEM_SIGOR",

	//Iframe
	evtIframeOnSuccessCallBack: "onSuccessCall",

	evtIframeOnSuccessCreateDriver: "onSuccessCallCreateDriver",
	evtIframeOnCancelCreateDriver: "onCancelCallCreateDriver",
	evtIframeOnSuccessCreateVehicle: "onSuccessCallCreateVehicle",
	evtIframeOnCancelCreateVehicle: "onCancelCallCreateVehicle",

	// content nav bar events
	evtStartLoadingPage: "START_LOADING_PAGE",
	evtFinishLoadingPage: "FINISH_LOADING_PAGE",
	evtGridDelete: "GRID_DELETE",
	evtGridCreate: "GRID_CREATE",
	evtGridCDF: "GRID_CDF",
	evtScreenDataRefresh: "SCREEN_DATA_REFRESH",
	evtGridFilter: "GRID_FILTER",
	evtGridFilterOnDate: "GRID_FILTER_ON_DATE",
	evtGridFilterTag: "GRID_FILTER_TAG",

	//authentication
	evtVgrLoginSuccess: "EVENT_VGR_LOGIN_SUCCESS",
	evtVgrLoginFailure: "EVENT_VGR_LOGIN_FAILURE",

	evtRefreshDisposalValidation: "refresh_disposal_validation",
	evtApplyAllFilters: "apply_all_filters",
	evtCleanTagFilter: "clean_tag_filter",
	evtClientExternalSystemChanged: "client_external_system_changed",
	evtErrorCreatingDisposal: "error_creating_disposal",
	evtDisposalResiduesUpdated: "disposal_residues_updated",
	evtRecalculateDisposalCost: "recalculate_disposal_cost",
	evtDisposalCostChanged: "disposal_cost_changed",
	evtOpenDisposalCostPopup: "open_disposal_cost_popup",
	evtOpenDisposalAddIntegrationsPopup: "open_disposal_add_integrations_popup",
	evtRecalculateDisable: "recalculate_disable",
	evtSetDisposalLoad: "set_disposal_load",
	evtDisposalFilesUpdated: "disposal:disposal_files_updated",
	graphql: {
		subscriptions: `subscription Subscribe2channel($name: String!) {
			subscribe2channel(name: $name) {
			  name
			  data
			}
		  }`,
		mutations: ` mutation Publish2channel($name: String!, $data: AWSJSON!) {
			publish2channel(name: $name, data: $data) {
			  name
			  data
			}
		  }`,
		queries: `query GetChannel {
			getChannel {
			  name
			  data
			}
		  }`
	},
	events: {
		cache: {
			userPermission: "evt_cacheUserPermission",
			featureToggle: "evt_cacheFeatureToggle",
			user: "evt_cacheUser"
		},
		table: {
			headCheckboxSelected: "evt_tableHeadCheckboxSelected",
			headCheckboxUnSelected: "evt_tableHeadCheckboxUnSelected"
		}
	},

	chartColors: ["#008281", "#A6690D", "#E02E39", "#803690", "#007CC3", "#6D7788", "#4D5360"],

	MapLocations: {
		BELO_HORIZONTE_MG: new Cmp.constructors.MapPoint(-20, -44) //(-19.9166813, -43.9344931)//(-20,-44)//
	},

	cacheKeysPrefix: {
		images: "VGR_S3_IMAGES_CACHE_",
		measureUnits: "MEASURE_UNITS_CACHE_",
		scales: "SCALES_CACHE_",
		disposalTypes: "DISPOSAL_TYPE_CORE_CACHE_",
		disposalPendencyTypes: "DISPOSAL_PENDENCY_TYPES_CACHE_",
		ibamaResidues: "IBAMA_RESIDUES_CACHE_",
		ibamaActivities: "IBAMA_ACTIVITIES_CACHE",
		suppliersList: "SUPPLIERS_LIST_CACHE",
		NBRList: "LIST_NBR_CACHE",
		conama358List: "LIST_CONAMA_358_CACHE",
		conama313List: "LIST_CONAMA_313_CACHE_",
		onuPictograms: "ONU_CRIPTOGRAMS_CACHE_",
		residueClass: "RESIDUE_CLASS_CACHE_",
		residueGroups: "RESIDUE_GROUPS_CACHE_",
		countries: "LIST_COUNTRIES_CACHE_",
		states: "LIST_STATES_CACHE_",
		cities: "CITIES_CACHE_"
	},

	VGclientID: 1,
	MasterOrganizationId: 1,
	IframeSingletonId: "newVertownSingleton",
	IframeSingletonBlankPage: "blank",
	IframeId: "portalAngular",

	showCostModuleAlerts: true
};
