angular.module("vgresiduos").controller("iframeAngularSingletonController", [
	"$scope",
	"$sce",
	"accountService",
	"localStorageWithTimeoutService",
	"translationService",
	"$interval",
	"$state",
	"$q",
	"$rootScope",
	function (
		$scope,
		$sce,
		accountService,
		localStorageWithTimeoutService,
		translationService,
		$interval,
		$state,
		$q,
		$rootScope
	) {
		const eventHandler = {
			REDIRECT: redirectTo,
			READY: syncLocalStorage,
			REDIRECT_BLANK: redirectBlank,
			ON_SUCCESS: executeOnSuccessCall,
			ON_CANCEL: executeOnCancelCall,
			GO_BACK: goBack,
			CHANGE_CLIENT: changeOrganizationUnitHandler,
			UPDATE_TOKEN: updateTokenHandler
		};

		$scope.labels = $rootScope.labels;

		const iframeId = Vgr.constants.IframeSingletonId;
		let refreshTokenTimer;
		let currentPath = Vgr.constants.IframeSingletonBlankPage;

		this.$onInit = function () {
			window.addEventListener("message", iframeEventHandler);
			refreshTokenTimer = $interval(refreshFrameToken, 1000 * 5 * 60); //Refresh Angular token every 5 minutes

			const finalUrl = `${Vgr.constants.newAngularUrl}`;
			currentPath = Vgr.constants.IframeSingletonBlankPage;
			$scope.src = $sce.trustAsResourceUrl(finalUrl);
		};

		$scope.showFrame = function () {
			return currentPath !== Vgr.constants.IframeSingletonBlankPage;
		};

		this.$onDestroy = function () {
			window.removeEventListener("message", iframeEventHandler);
			$interval.cancel(refreshTokenTimer);
			watchFrameRedirection();
			watchChangeClient();
		};

		function iframeEventHandler(event) {
			if (Vgr.constants.newAngularUrl.includes(event.origin) && event.data.type) {
				eventHandler[event.data.type](event);
			}
		}

		function changeOrganizationUnitHandler(event) {
			const organizationUnitId = event.data.payload.organizationUnitId;
			const ignoreErrorMessageHandling = false;

			accountService.changeClient(organizationUnitId, ignoreErrorMessageHandling).then(function () {
				$state.go("clientEdit", {
					id: organizationUnitId
				});
			});
		}

		function executeOnSuccessCall(event) {
			if (event && event.data && event.data.payload && event.data.payload.createVehicle) {
				$scope.$emit(Vgr.constants.evtIframeOnSuccessCreateVehicle, event.data.payload);
			} else if (event && event.data && event.data.payload && event.data.payload.createDriver) {
				$scope.$emit(Vgr.constants.evtIframeOnSuccessCreateDriver, event.data.payload);
			} else {
				$scope.$emit(Vgr.constants.evtIframeOnSuccessCallBack, event);
			}
		}

		function executeOnCancelCall(event) {
			if (event && event.data && event.data.payload && event.data.payload.createVehicle) {
				$scope.$emit(Vgr.constants.evtIframeOnCancelCreateVehicle, event);
			} else if (event && event.data && event.data.payload && event.data.payload.createDriver) {
				$scope.$emit(Vgr.constants.evtIframeOnCancelCreateDriver, event);
			}
		}

		function redirectTo(event) {
			$state.go(event.data.payload.state, event.data.payload.routeData);
		}

		function redirectBlank(event) {
			window.open(event.data.payload.blankUrl, "_blank");
		}

		function getPermissions() {
			return localStorageWithTimeoutService.getFromCacheWithExpiry(
				Vgr.constants.authentication.permissions,
				Vgr.constants.events.cache.userPermission
			);
		}
		function getFeatureToggle() {
			return localStorageWithTimeoutService.getFromCacheWithExpiry(
				Vgr.constants.authentication.featureToggle,
				Vgr.constants.events.cache.featureToggle
			);
		}

		function refreshFrameToken() {
			getBearerToken().then((token) => {
				if (token) {
					sendMessageToFrame(Vgr.enumerations.messageTypes.SET_BEARER_TOKEN, { token: token });
				}
			});
		}

		function syncLocalStorage() {
			getBearerToken().then((token) => {
				const currentLanguage = translationService.getLanguage();
				const userPermissions = getPermissions();
				const organization = accountService.getCurrentClient();
				const featureToggles = getFeatureToggle();
				const loggedUser = accountService.getLoggedAccount();
				sendMessageToFrame(Vgr.enumerations.messageTypes.SYNC_LOCAL_STORAGE, {
					userPermissions,
					organization,
					featureToggles,
					currentLanguage,
					loggedUser,
					token
				});
			});
		}

		function getBearerToken() {
			if (!document.getElementById(iframeId)) {
				return;
			}

			const deferred = $q.defer();
			accountService.getTokenRefreshingIfNecessary().then((tokens) => {
				if (tokens) {
					deferred.resolve(tokens.accessToken);
				} else {
					deferred.resolve();
				}
			});
			return deferred.promise;
		}

		function sendMessageToFrame(type, payload) {
			document.getElementById(iframeId).contentWindow.postMessage(
				{
					type: type,
					payload: payload
				},
				Vgr.constants.newAngularUrl
			);
		}

		function updateTokenHandler() {
			syncLocalStorage();
		}

		$(`#${iframeId}`).ready(function () {
			$(`#${iframeId}`).on("load", function () {
				if (Vgr.util.tempLastRoute) {
					Vgr.util.redirectSingleton($rootScope, Vgr.util.tempLastRoute.route, Vgr.util.tempLastRoute.queryString);
					Vgr.util.tempLastRoute = null;
				}
			});
		});

		const watchChangeClient = $rootScope.$on(Vgr.constants.evtChangeLoggedClient, function () {
			syncLocalStorage();
		});

		const watchFrameRedirection = $rootScope.$on("REDIRECT_FRAME_SINGLETON", function (_event, params) {
			createQueryParamsV2(params.queryString).then((queryParams) => {
				currentPath = params.route;
				redirectSingleton(params.route, queryParams);
			});
		});

		function redirectSingleton(route, params) {
			sendMessageToFrame(Vgr.enumerations.messageTypes.REDIRECT_SINGLETON, {
				route: route,
				params: params
			});
		}

		function createQueryParamsV2(queryString) {
			const deferred = $q.defer();
			const queryParams = {};
			const withHeader = $scope.ctrl.withHeader ? $scope.ctrl.withHeader : false;
			queryParams.withHeader = withHeader;
			if (queryString) {
				queryString.forEach((queryStringParameter) => {
					if (queryStringParameter.key && queryStringParameter.value) {
						queryParams[queryStringParameter.key] = queryStringParameter.value;
					}
				});
			}

			const currentOrganizationUnit = accountService.getCurrentClient();
			if (currentOrganizationUnit) {
				const organizationId = currentOrganizationUnit.organization.id;
				const organizationUnitId = currentOrganizationUnit.id;
				const organizationUnitCode = currentOrganizationUnit.code;
				queryParams.organizationId = organizationId;
				queryParams.organizationUnitId = organizationUnitId;
				queryParams.organizationUnitCode = organizationUnitCode;
			}
			deferred.resolve(queryParams);
			return deferred.promise;
		}

		function goBack() {
			window.history.back();
		}
	}
]);
