angular.module("vgresiduos").controller("EsgEmbedPowerbiCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, "painel-de-emissoes");
		};
	}
]);
