angular.module("vgresiduos").controller("DisposalCrtl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `disposal/${$stateParams.id}`, [
				{ key: "origem", value: $stateParams.origem }
			]);
		};
	}
]);
