Vgr.trackings = {
	general: {
		blockPageInterest: "imInterested",
		login: "login"
	},
	disposal: {
		followupAccess: {
			usingMenu: "goToDisposalsFollowupUsingMenu"
		},
		flow: {
			durationCreateDestination: "durationToCreateDestination"
		},
		manifest: {
			followupAccessUsingMenu: "goToDisposalManifestsFollowupUsingMenu",
			followupAccessUsingDisposalFollowupScreen: "goToDisposalManifestsFollowupUsingDisposalFollowupScreen"
		},
		history: {
			attachCdf: "HistoricoDestinacao.Listagem.AnexarCdf",
			exportHistory: "HistoricoDestinacao.Listagem.ExportarCsv"
		}
	},
	internal: {
		gathering: {
			exportCsv: "Geracao.TelaAcompanhamento.ExportarCsv",
			goToNewPage: "Geracao.TelaAntiga.AcessarNovaTela",
			create: "Geracao.TelaAntiga.Criar",
			createSuccess: "Geracao.TelaAntiga.Criar.Sucesso",
			print: "Geracao.TelaAntiga.Imprimir",
			edit: "Geracao.TelaAntiga.Editar"
		},
		transfer: {
			closePopupNewFeature: "MovimentacaoInterna.FecharPopup",
			accessManualNewFeature: "MovimentacaoInterna.ManualAcessado"
		}
	},
	residueTraceability: {
		usingMenu: "RastreabilidadeResiduos.Abrir.Menu",
		accessManualNewFeature: "BI.RastreabilidadeResiduos.AcessarManual"
	},
	supplier: {
		actionPlan: {
			create: "Fornecedores.Editar.PlanoDeAcao.Criar"
		},
		audit: {
			print: "Fornecedores.Editar.Auditorias.Imprimir",
			requestSupplier: "Fornecedores.Editar.Auditorias.SolicitarPreenchimentoFornecedor"
		},
		supplierFilter: {
			accessPopupManual: "Fornedecores.FiltroPendencias.ManualAcessado",
			closePopupNewFeature: "Fornedecores.FiltroPendencias.Fechar"
		},
		organizationUnitControl: "Fornecedores.ControleUnidade",
		linkUnlinkSupplier: "Fornecedores.VincularDesvincular",
		filterByStatus: "Fornecedores.FiltrarPorStatus",
		edit: "Fornecedores.Editar.Click",
		accessReplicatePopup: "Fornecedores.Replicar.Popup.Antigo"
	},
	area: {
		create: "Area.Criar"
	},
	residue: {
		create: "Residuo.Criar"
	},
	mtrFollowup: {
		accessPageByDestination: "goToMtrFollowupByDestination"
	},
	dashboard: {
		usingMenu: "Dashboard.Acessar",
		opportunities: {
			clickedOnAnOpportunity: "Dashboard.Oportunidades.Clicar",
			createIndicationRequest: "Dashboard.Oportunidades.CriarSolicitacaoMercado"
		},
		pendencies: {
			clickedOnAPendency: "Dashboard.Pendencias.Clicar"
		}
	},
	bi: {
		metrics: {
			residueGenerationQuantity: "BI.Metrica.VisualizarDadosGeracaoQuantidade",
			residueDisposalQuantity: "BI.Metrica.VisualizarDadosDestinacaoQuantidade",
			residueDisposalCosts: "BI.Metrica.VisualizarDadosDestinacaoCustos",
			residueDisposalRecipientEfficiency: "BI.Metrica.VisualizarDadosDestinacaoEficienciaAcondicionamento",
			productionQuantity: "BI.Metrica.VisualizarDadosProducaoQuantidade",
			goalResidueGenerationQuantity: "BI.Metrica.VisualizarDadosMetasGeracaoQuantidade",
			goalResidueDisposalCosts: "BI.Metrica.VisualizarDadosMetasDestinacaoCusto"
		},
		reports: {
			createSavedReport: "BI.Relatorios.CriarRelatorioSalvo",
			generateSavedReport: "BI.Relatorios.GerarRelatorioSalvo",
			generateKpi: "BI.Relatorios.GerarIndicador",
			generateRelativeKpi: "BI.Relatorios.GerarComparacaoIndicadores",
			generateGoal: "BI.Relatorios.GerarMetaRealizado",
			generateManagementInventory: "BI.Relatorios.GerarInventarioGerencial",
			generateCadriBalance: "BI.Relatorios.GerarSaldoFornecedor",
			generateResidueMovimentation: "BI.Relatorios.GerarMovimentacaoResiduos"
		},
		customReport: {
			accessManualNewFeature: "BI.Relatorios.AcessarManual"
		},
		emission: {
			accessManualNewFeature: "BI.Emissoes.Dashboard.AcessarManual"
		}
	},
	maturity: {
		access: "Maturidade.Acessar.Menu"
	},
	indicativeReport: {
		accessPage: "Bi.RelatorioIndicativo.AcessarDashboard",
		accessManualNewFeature: "Bi.RelatorioIndicativo.AcessarManual"
	},
	declarations: {
		rappIbamaGenerateReport: "Declaracao.RappIbama.GerarRelatorio",
		conama313GenerateReport: "Declaracao.Conama313.GerarRelatorio",
		dnitGenerateReport: "Declaracao.Dnit.GerarRelatorio"
	}
};
