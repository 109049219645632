angular.module("vgresiduos").controller("disposalModelsCtrl", [
	"$rootScope",
	function ($rootScope) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `disposal-models`);
		};
	}
]);
