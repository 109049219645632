angular.module("vgresiduos").factory("featureToggleService", [
	"$http",
	"$q",
	"localStorageWithTimeoutService",
	function ($http, $q, localStorageWithTimeoutService) {
		"use strict";
		const cacheTimeoutInMinutes = 4 * 60;

		function listFeatureToggleAccess(dto) {
			const req = {
				method: "post",
				data: dto,
				url: Vgr.constants.sharedServicesHostUrl + Vgr.resources.sharedServices.featureToggles + "access"
			};

			return $http(req);
		}

		const _loadFeatureToggles = function (organizationId, organizationUnitId, userCode) {
			const deferred = $q.defer();

			const dto = {
				organizationId,
				organizationUnitId,
				userCode
			};

			listFeatureToggleAccess(dto).then(
				function (response) {
					localStorageWithTimeoutService.setInCacheWithExpiry(
						Vgr.constants.authentication.featureToggle,
						response.data,
						cacheTimeoutInMinutes,
						Vgr.constants.events.cache.featureToggle
					);
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		const _hasFeatureToggle = function (featureToggleId) {
			const toggles = localStorageWithTimeoutService.getFromCacheWithExpiry(
				Vgr.constants.authentication.featureToggle,
				Vgr.constants.events.cache.featureToggle
			);

			if (toggles) {
				const toggleId = featureToggleId.toLowerCase();
				const featureToggle = toggles.find((toggle) => toggle.id.toLowerCase() === toggleId);

				return featureToggle ? true : false;
			} else {
				return false;
			}
		};

		return {
			loadFeatureToggles: _loadFeatureToggles,
			hasFeatureToggle: _hasFeatureToggle
		};
	}
]);
