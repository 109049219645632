angular.module("vgresiduos").factory("engagementScoreService", [
	"$http",
	"permissionService",
	"localStorageService",
	"accountService",
	"applicationInsightsService",
	function ($http, permissionService, localStorageService, accountService, applicationInsightsService) {
		"use strict";

		const lastClientLoginStorageKey = "lastClientLogin";

		function isLogDisabledForUser() {
			return permissionService.isMaster();
		}

		/*****************IDENTIFY************************/

		const _identify = function identify() {
			const client = accountService.getCurrentClient();
			if (!client || isLogDisabledForUser()) {
				return;
			}

			applicationInsightsService.setAuthenticatedUserContext(client.id);

			if (hadAlreadyLoggedIn(client.id)) {
				return;
			}
			setLastLogin(client.id);

			_track(Vgr.trackings.general.login);
		};

		function hadAlreadyLoggedIn(clientId) {
			const lastLoginDate = getLastLogin()[clientId];
			if (!lastLoginDate) {
				return false;
			}
			return Vgr.date.isSameDay(Vgr.date.fromISOToMoment(lastLoginDate), Vgr.date.fromISOToMoment(new Date()));
		}

		function setLastLogin(clientId) {
			const lastLogin = getLastLogin();
			lastLogin[clientId] = new Date();
			localStorageService.set(lastClientLoginStorageKey, lastLogin);
		}

		function getLastLogin() {
			let lastLogin = localStorageService.get(lastClientLoginStorageKey);
			if (!lastLogin) {
				lastLogin = {};
			}
			return lastLogin;
		}

		/*****************TRACK************************/

		const _track = function track(eventCode, properties, information) {
			const user = accountService.getLoggedAccount();
			const client = accountService.getCurrentClient();
			if (!user || !client || isLogDisabledForUser()) {
				return;
			}

			const req = {
				method: "post",
				data: {
					EventCode: eventCode,
					OrganizationId: client.organization.id,
					OrganizationUnitId: client.id,
					Information: information
				},
				url: Vgr.constants.sharedServicesHostUrl + Vgr.resources.sharedServices.trackings
			};

			return $http(req);
		};

		/*****************TRACK DURATION************************/

		const _trackDuration = function track(event, duration) {
			const user = accountService.getLoggedAccount();
			if (!user || isLogDisabledForUser()) {
				return;
			}

			applicationInsightsService.trackPageView({ measurements: { [event]: duration } });
		};

		/*****************************************/

		return {
			identify: _identify,
			track: _track,
			trackDuration: _trackDuration
		};
	}
]);
